import { light } from '@pancakeswap/uikit'
import { Store } from '@reduxjs/toolkit'
import { LanguageProvider } from 'contexts/Localization'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'

const StyledThemeProvider = (props) => {
  return (
    <ThemeProvider
      // theme={resolvedTheme === 'dark' ? dark : light}
      theme={light}
      {...props}
    />
  )
}

const Providers: React.FC<{ store: Store }> = ({ children, store }) => {
  return (
    <Provider store={store}>
      <StyledThemeProvider>
        <LanguageProvider>{children}</LanguageProvider>
      </StyledThemeProvider>
    </Provider>
  )
}

export default Providers
